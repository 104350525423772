import _ from 'lodash';
import moment from 'moment';
import { GroupBase, StylesConfig } from 'react-select';
import { IContact } from '../../api/associate/interfaces';
import i18n from '../../i18n/i18nInit';
import {
    COLOR_DARK,
    COLOR_INPUT,
    COLOR_PRIMARY,
    COLOR_SELECT_PLACEHOLDER,
    DriverLoadAttachmentType,
} from '../constants';
import { SpecialCharactersURLEncoder } from '../constants/common';
import { IGenericObject, ISelect, NullableDate } from '../interfaces';
import { FormFieldType } from '../validations/interfaces';
import { validateAccordingToType } from '../validations';
import heic2any from 'heic2any';
import { notifyError } from '../../api/axios';
import { t } from 'i18next';
import { ShipmentLocationType } from '../../pages/LoadBoard/enum';

export const currentDateString = (): string => {
    return new Date().toISOString().split('T')[0];
};

export const deepClone: <T>(data: T) => T = data => {
    return _.cloneDeep(data);
};

export const SelectStyles: StylesConfig<ISelect, false> = {
    control: provided => ({
        ...provided,
        background: COLOR_INPUT,
        color: COLOR_PRIMARY,
        border: '0',
        boxShadow: 'none',
        minHeight: '35px',
        height: '35px',
        '&:focus-within': {
            boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
        },
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '0.875rem',
        color: COLOR_SELECT_PLACEHOLDER,
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999,
    }),
    option: (provided, state) => {
        const isEvenIndex =
            state.options.findIndex(option => option === state.data) % 2 === 0;
        const background = isEvenIndex ? 'transparent' : COLOR_INPUT;

        return {
            ...provided,
            background,
            color: COLOR_DARK,
        };
    },
};

export const MultiSelectStyles: StylesConfig<
    ISelect,
    true,
    GroupBase<ISelect>
> = {
    control: provided => ({
        ...provided,
        background: COLOR_INPUT,
        color: COLOR_PRIMARY,
        border: '0',
        boxShadow: 'none',
        minHeight: '35px',
        overflow: 'hidden',
        '&:focus-within': {
            boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
        },
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '0.875rem',
        color: COLOR_SELECT_PLACEHOLDER,
    }),
    option: (provided, state) => {
        const isEvenIndex =
            state.options.findIndex(option => option === state.data) % 2 === 0;
        const background = isEvenIndex ? 'transparent' : COLOR_INPUT;

        return {
            ...provided,
            background,
            color: COLOR_DARK,
        };
    },
};

export const TimePickerSelectStyles: StylesConfig<ISelect, false> = {
    ...SelectStyles,
    dropdownIndicator: provided => ({
        ...provided,
        display: 'none',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
};

export const StateSelectStyles: StylesConfig<ISelect, false> = {
    ...SelectStyles,
    valueContainer: provided => ({
        ...provided,
        width: '60px',
    }),
};

export const formatDate: (
    date: moment.MomentInput,
    dateFormat?: string,
) => string = (date: moment.MomentInput, dateFormat = 'MM/DD/YYYY') => {
    if (!date) return '';
    return moment.utc(date).format(dateFormat);
};

export const formatDropdownList = (
    response: IGenericObject[],
    id: string,
    name: string,
): ISelect[] => {
    const dropdownList: ISelect[] = [];
    response.forEach(dropdownDetail => {
        dropdownList.push({
            value: `${dropdownDetail[id]}` || '',
            label: `${dropdownDetail[name]}` || '',
        });
    });
    return dropdownList;
};

export const getDefaultSelectOption = (labelPath: string): ISelect => ({
    value: '0',
    label: i18n.t(labelPath),
});

export const encodeSpecialCharacters = (searchString: string): string => {
    return searchString.replace(/[#&]/g, character => {
        return SpecialCharactersURLEncoder[character] || character;
    });
};

export const addExtensionToContacts = (contacts: IContact[]): IContact[] => {
    return contacts.map(contact => ({
        ...contact,
        phoneNumber: contact.ext
            ? `${contact.phoneNumber}-${contact.ext}`
            : contact.phoneNumber,
    }));
};

export const getFileObject = (image: string): File => {
    const base64Data = image.split(',')[1];
    const binaryData = atob(base64Data);
    const imageType = image.split(';')[0].split(':')[1];

    const blob = new Blob(
        [new Uint8Array([...binaryData].map(char => char.charCodeAt(0)))],
        { type: imageType },
    );

    const file = new File([blob], `image.${imageType.split('/')[1]}`, {
        type: imageType,
    });

    return file;
};

export const dateFormatter = (dateString: string | Date = ''): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const splitDateString = (dateString: string): string => {
    return dateString.split('T')[0];
};

export const parseUTCDate = (
    dateString: string | Date | NullableDate,
): Date | null => {
    if (typeof dateString === 'string') {
        const dateParts = dateString.split('T')[0].split('-').map(Number);

        if (dateParts.length === 3) {
            const [year, month, day] = dateParts;

            if (
                !isNaN(year) &&
                !isNaN(month) &&
                !isNaN(day) &&
                month >= 1 &&
                month <= 12
            ) {
                return new Date(
                    Date.UTC(year, month - 1, day, 23, 59, 59, 999),
                );
            }
        }
    } else if (dateString instanceof Date) {
        const dateCopy = new Date(dateString);
        return dateCopy;
    }

    return null;
};

export const getRoundOffNumber = (value: number): number => {
    return Math.round(value * 100) / 100;
};

export const extractHoursAndMinutes = (dateString: string): string => {
    const dateTimeParts = dateString.split('T');

    const timePart = dateTimeParts[1];

    const timeParts = timePart.split(':');

    const hours = timeParts[0];
    const minutes = timeParts[1];

    return `${hours}:${minutes}`;
};

export const getCurrentDateTime = (): string => {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear().toString().slice(-2);
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDateTime.getDate().toString().padStart(2, '0');
    const hours = currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const getCurrentMonthName = (): string => {
    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('en-US', {
        month: 'long',
    });

    return currentMonthName;
};

export const covertBytesToMb = (bytes: number): number => {
    return bytes / (1024 * 1024);
};

export const validateListOfEmails = (email: string): boolean => {
    return email
        .split(',')
        .map(email => email.trim())
        .every(email =>
            validateAccordingToType(email, 'email' as FormFieldType),
        );
};

const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const isValidDate = (
    month: number,
    day: number,
    year: number,
): boolean => {
    const monthLengths = [
        31,
        isLeapYear(year) ? 29 : 28,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
    ];
    return day > 0 && day <= monthLengths[month - 1];
};

export const transformString = (str: string): string[] => {
    const noBrackets = str.slice(2, -2).replace(/\\/g, '');
    const elements = noBrackets.split(',');
    const cleanedElements = elements.map(element =>
        element.replace(/['"]+/g, '').trim(),
    );

    return cleanedElements;
};

export const toCamelCase = (str: string): string => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return '';
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};

export const isEmptyOrWhitespace = (value: string): boolean => {
    return /^\s*$/.test(value);
};

export const convertHeicHeifToJpg = async (file: File): Promise<File> => {
    try {
        const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
        });
        const blobParts = Array.isArray(convertedBlob)
            ? convertedBlob
            : [convertedBlob];
        const newFileName = file.name.replace(/\.[^/.]+$/, '.jpeg');
        return new File(blobParts, newFileName, {
            type: 'image/jpeg',
        });
    } catch (error) {
        notifyError('Error converting HEIC/HEIF to JPEG');
        throw error;
    }
};

export const getTrailerSize = (trailerSize: string): string => {
    let trailer;
    switch (trailerSize) {
        case '_49k':
            trailer = '49k';
            break;
        case '_52k':
            trailer = '52k';
            break;
        case '_55_62k':
            trailer = '55-62k';
            break;
        default:
            trailer = '';
    }
    return trailer;
};

export const filterSpecialCharacter = (value: string): string => {
    const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    return formattedValue;
};

export const formatDocumentType = (
    documentType: string | undefined,
): string => {
    if (!documentType) {
        return '';
    }
    const attachment = DriverLoadAttachmentType.find(
        item => item.value === documentType,
    );

    return attachment ? attachment.label : documentType;
};

export const getLocationType = (locationType: string): string => {
    let locationTypeLabel;
    switch (locationType) {
        case ShipmentLocationType.Pickup:
            locationTypeLabel = t('Common:pickUpLabel');
            break;
        case ShipmentLocationType.Other:
            locationTypeLabel = t('Common:otherLabel');
            break;
        case ShipmentLocationType.DropOff:
            locationTypeLabel = t('Common:dropOffLabel');
            break;
        default:
            locationTypeLabel = '';
    }
    return locationTypeLabel;
};

export const filterContacts = (contacts: IContact[]): IContact[] => {
    return contacts.filter(
        contactDetail =>
            contactDetail.email ||
            contactDetail.ext ||
            contactDetail.name ||
            contactDetail.phoneNumber ||
            contactDetail.title,
    );
};
