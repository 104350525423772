import { ISetting } from '../../api/settings/interface/ISetting';
import { settingsApi } from '../../api/settings/settingsApi';
import { InvoicingToolType } from './components/CommunicationAndInvoicePreferences/enum';
import {
    BROKER_FEE_AMOUNT,
    BROKER_FEE_TYPE,
    CITY,
    COMPANY_ADDRESS,
    COMPANY_LOGO,
    COMPANY_NAME,
    DISPATCH_SUPPORT_NUMBER,
    EMAIL_ADDRESS,
    FAX_NUMBER,
    PHONE_NUMBER,
    SETTLEMENT_REPORT_BATCH_NUMBER,
    STATE,
    ZIP_CODE,
    IS_GREETING_MESSAGE_INCLUDED,
    IS_ORDER_NUMBER_INCLUDED,
    IS_DATE_INCLUDED,
    IS_TIME_FORMAT_12_HR,
    IS_PIN_DROP_NAME_INCLUDED,
    IS_LOCATION_INSTRUCTION_INCLUDED,
    IS_ESTIMATED_WEIGHT_INCLUDED,
    IS_ESTIMATED_HEAD_COUNT_INCLUDED,
    IS_COMMODITY_INCLUDED,
    IS_DRIVER_INSTRUCTION_INCLUDED,
    IS_ENDING_MESSAGE_INCLUDED,
    ENDING_MESSAGE,
    GREETING_MESSAGE,
    IS_SEND_INVOICE,
    INVOICE_RECIPIENTS,
} from './constants';
import {
    ICompanyDetails,
    ICommunicationDetails,
    IInvoicingDetails,
} from './interfaces/ISettingsDetails';

export interface ISettingUtils {
    getCompanyDetails: (settings: ISetting[]) => ICompanyDetails;
    getCommunicationDetails: (settings: ISetting[]) => ICommunicationDetails;
    getInvoicingDetails: (settings: ISetting[]) => IInvoicingDetails;
}

export class SettingUtils implements ISettingUtils {
    private settings: ISetting[];

    constructor(settings: ISetting[]) {
        this.settings = settings;
    }

    private getCompanyValue(companyName: string): string {
        const setting = this.settings.find(
            setting => setting.name === companyName,
        );
        return setting?.value !== null ? String(setting?.value) : '';
    }

    private getBooleanSettingValue(settingName: string): boolean {
        const setting = this.settings.find(
            setting => setting.name === settingName,
        );
        return setting?.value === true;
    }

    getCompanyDetails = (): ICompanyDetails => {
        const companyLogoSetting = this.settings.find(
            setting => setting.name === COMPANY_LOGO,
        );
        const brokerFeeType = this.getCompanyValue(BROKER_FEE_TYPE);
        const brokerFeeAmount = this.getCompanyValue(BROKER_FEE_AMOUNT);
        const companyName = this.getCompanyValue(COMPANY_NAME);
        const companyAddress = this.getCompanyValue(COMPANY_ADDRESS);
        const city = this.getCompanyValue(CITY);
        const state = this.getCompanyValue(STATE);
        const zipCode = this.getCompanyValue(ZIP_CODE);
        const phoneNumber = this.getCompanyValue(PHONE_NUMBER);
        const dispatchSupportNumber = this.getCompanyValue(
            DISPATCH_SUPPORT_NUMBER,
        );
        const faxNumber = this.getCompanyValue(FAX_NUMBER);
        const emailAddress = this.getCompanyValue(EMAIL_ADDRESS);
        const companyLogo = this.getCompanyValue(COMPANY_LOGO);
        const baseSettingId = companyLogoSetting?.baseSettingId;
        const settlementReportBatchNumber = this.getCompanyValue(
            SETTLEMENT_REPORT_BATCH_NUMBER,
        );

        return {
            brokerFeeType,
            brokerFeeAmount,
            companyName,
            emailAddress,
            phoneNumber,
            faxNumber,
            dispatchSupportNumber,
            companyAddress,
            city,
            state,
            zipCode,
            companyLogo,
            baseSettingId,
            settlementReportBatchNumber,
        };
    };

    getCommunicationDetails = (): ICommunicationDetails => {
        const greetingMessage = this.getCompanyValue(GREETING_MESSAGE);
        const endingMessage = this.getCompanyValue(ENDING_MESSAGE);
        const isGreetingMessageIncluded = this.getBooleanSettingValue(
            IS_GREETING_MESSAGE_INCLUDED,
        );
        const isEndingMessageIncluded = this.getBooleanSettingValue(
            IS_ENDING_MESSAGE_INCLUDED,
        );
        const isDriverInstructionIncluded = this.getBooleanSettingValue(
            IS_DRIVER_INSTRUCTION_INCLUDED,
        );
        const isOrderNumberIncluded = this.getBooleanSettingValue(
            IS_ORDER_NUMBER_INCLUDED,
        );
        const isDateIncluded = this.getBooleanSettingValue(IS_DATE_INCLUDED);
        const isTimeFormat12Hr =
            this.getBooleanSettingValue(IS_TIME_FORMAT_12_HR);
        const isPinDropNameIncluded = this.getBooleanSettingValue(
            IS_PIN_DROP_NAME_INCLUDED,
        );
        const isLocationInstructionIncluded = this.getBooleanSettingValue(
            IS_LOCATION_INSTRUCTION_INCLUDED,
        );
        const isEstimatedWeightIncluded = this.getBooleanSettingValue(
            IS_ESTIMATED_WEIGHT_INCLUDED,
        );
        const isEstimatedHeadCountIncluded = this.getBooleanSettingValue(
            IS_ESTIMATED_HEAD_COUNT_INCLUDED,
        );
        const isCommodityIncluded = this.getBooleanSettingValue(
            IS_COMMODITY_INCLUDED,
        );

        return {
            greetingMessage,
            endingMessage,
            isGreetingMessageIncluded,
            isEndingMessageIncluded,
            isDriverInstructionIncluded,
            isOrderNumberIncluded,
            isDateIncluded,
            isTimeFormat12Hr,
            isPinDropNameIncluded,
            isLocationInstructionIncluded,
            isEstimatedWeightIncluded,
            isEstimatedHeadCountIncluded,
            isCommodityIncluded,
        };
    };

    getInvoicingDetails = (): IInvoicingDetails => {
        const getRecipientsValue = (companyName: string): string[] => {
            const setting = this.settings.find(
                setting => setting.name === companyName,
            );

            if (setting !== undefined) {
                return setting?.value as string[];
            }
            return [];
        };

        const isSendInvoices = this.getBooleanSettingValue(IS_SEND_INVOICE);
        const invoiceRecipients = getRecipientsValue(INVOICE_RECIPIENTS);
        const invoicingToolType = InvoicingToolType.OtherTool;

        return {
            isSendInvoices,
            invoiceRecipients,
            invoicingToolType,
        };
    };

    async fetchTenantSettings(): Promise<ICompanyDetails | undefined> {
        try {
            const companyDetail = this.getCompanyDetails();

            if (companyDetail.companyLogo) {
                const companyLogoDetails = companyDetail.companyLogo;
                const formattedCompanyLogoDetails =
                    JSON.parse(companyLogoDetails);
                const attachmentId = formattedCompanyLogoDetails.AttachmentId;
                const accessToken = formattedCompanyLogoDetails.AccessToken;

                const attachmentResponse =
                    await settingsApi.fetchCompanyProfilePicture(
                        companyDetail.baseSettingId || 0,
                        accessToken,
                        attachmentId,
                    );

                const logoUrl = URL.createObjectURL(attachmentResponse.data);
                if (companyDetail.companyLogo !== logoUrl) {
                    companyDetail.companyLogo = logoUrl;
                }
                return companyDetail;
            } else {
                return companyDetail;
            }
        } catch {
            return undefined;
        }
    }
}
