import { getFeatureFlagValue } from './helper';
import { IDispatchManagerFeatures } from './interface';

export const dispatchManagerFeatures: IDispatchManagerFeatures = {
    isHideIntimetecMailIdUsers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_HIDE_INTIMETEC_MAIL_ID_USERS,
    ),
    isDM196787ManageTrailer: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_MANAGE_TRAILER_FE,
    ),
    isPrivacyPolicyLink: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_PRIVACY_POLICY_LINK,
    ),
    isHideReassignTrailer: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_DM196696_REASSIGN_TRAILER,
    ),
    isRescueLocationRemoved: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_RESCUE_LOCATION_REMOVED,
    ),
    isShowDeathLossReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_DEATH_LOSS_REPORT,
    ),
    isShowCommunicationSection: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_COMMUNICATION_SECTION,
    ),
    isShowSettingsInvoicing: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_SETTINGS_INVOICING,
    ),
    isShowInputAndDocumentPreferences: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_INPUT_AND_DOCUMENT_PREFERENCES,
    ),
    isShowDriverContact: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ADD_CONTACT_DRIVER,
    ),
    isShowTrailerIncomeReport: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_TRAILER_INCOME_REPORT,
    ),
};
